import React from 'react';
import { useSelector } from 'react-redux';

const useTranslation = () => {
  const {
    translationsLoaded,
    translationsList,
    preferredLanguage = 'en_uk',
  } = useSelector(({ profile }) => ({
    preferredLanguage: profile.user.userDetails.preferred_lang,
    translationsLoaded: profile.user.translationsLoaded,
    translationsList: profile.user.translationsList,
  }));

  const getTranslatedText = (key) => {
    if (translationsList) {
      return (
        translationsList?.[preferredLanguage]?.[key.toLowerCase()] ||
        translationsList?.['en_uk']?.[key]
      );
    }
  };

  return {
    t: getTranslatedText,
    isTranslationReady: translationsLoaded,
  };
};

export default useTranslation;
