import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SiderBar from './Sider';
import HeaderLayout from './Header';
import FooterLayout from './Footer';
import { Layout as AppLayout, Tabs, Modal, Spin, theme, Tooltip } from 'antd';
import { QuestionCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { addTab, removeTab, setGridEditCount } from './redux/modules/layout';
import { useShallowEqualSelector } from '../../helpers/utils';
import SearchCriteria from 'modules/pc/search/SearchCriteria';
import { useTranslation } from 'hooks';

const { Content, Sider, Header } = AppLayout;
const { TabPane } = Tabs;

const Layout = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    tabs,
    copyRights,
    portalTitle,
    broadcast,
    authorizationRequest,
    dashboardClicked,
    tabCreating,
    defaultAccess,
    portalVersion,
    hostName,
    isUserHasAccess,
    tourReferences,
  } = useShallowEqualSelector(({ layout, authorization, tour }) => ({
    tabs: layout.tabLayout.tabs,
    copyRights: authorization.initAuthorization.copyRights,
    portalTitle: authorization.initAuthorization.portalTitle,
    broadcast: authorization.initAuthorization.broadcast,
    authorizationRequest: authorization.loading,
    dashboardClicked: layout.contentLayoutSwitched,
    tabCreating: layout.tabCreating,
    defaultAccess: authorization.initAuthorization.defaultAccess,
    portalVersion: authorization.initAuthorization.version,
    hostName: authorization.initAuthorization.hostname,
    isUserHasAccess: authorization.isUserHasAccess,
    tourReferences: tour.tourReferences,
  }));

  const activeKey = useSelector(
    ({ layout: { tabLayout } }) => tabLayout.activeKey
  );

  const gridEditDetails = useSelector(({ layout }) => layout.gridEditDetails);

  const isGridEdited =
    gridEditDetails[activeKey] &&
    gridEditDetails[activeKey]['gridEditCount'] > 0;

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const {
    token: { colorBgContainer, colorText, colorBgHeader },
  } = theme.useToken();

  const add = (type, title) => {
    if (tabs.length + 1 > 6) {
      Modal.warning({
        title: 'Too many tabs',
        content:
          'You have tried to open too many tabs. Please close an existing tab and try again.',
      });
    } else {
      dispatch(addTab(type, title));
    }
  };

  const remove = (targetKey) => {
    if (isGridEdited && activeKey === targetKey) {
      Modal.confirm({
        title: 'Confirm',
        icon: <QuestionCircleOutlined />,
        content: t(
          'you_have_unsaved_changes_that_will_be_lost_if_you_continue'
        ),
        onOk: () => {
          dispatch(removeTab(targetKey));
          dispatch(setGridEditCount({ countType: 'reset', tabKey: targetKey }));
        },
        onCancel: () => {
          /* Do nothing */
        },
        cancelText: t('stay_on_this_page'),
        okText: t('continue'),
      });
    } else {
      dispatch(removeTab(targetKey));
    }
  };

  const funcCollection = { remove };
  const onEdit = (targetKey, action) => funcCollection[action](targetKey);
  const onChange = (key) => {
    dispatch({ type: 'layout/SWITCH_TAB_SUCCESS', payload: key });
  };
  const tabExists = tabs && tabs.length > 0;

  const getTabTitle = (currentKey, paneObj) => {
    if (defaultAccess === 'PC') {
      return currentKey === paneObj.key ? (
        paneObj.title
      ) : (
        <Tooltip title={paneObj.title} placement='top'>
          {paneObj.title.substr(0, 7)}..
        </Tooltip>
      );
    } else {
      return paneObj.title;
    }
  };

  return (
    <AppLayout className={`${defaultAccess === 'PC' ? 'pc-sider' : ''}`}>
      <Sider
        style={{ background: colorBgContainer, color: colorText }}
        trigger={null}
        breakpoint={{ xs: '480px', sm: '576px' }}
        collapsible
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
        collapsedWidth={0}>
        <SiderBar
          addTab={(type, title) => add(type, title)}
          toggle={() => setCollapsed(!collapsed)}
          portalTitle={portalTitle}
          collapsed={collapsed}
        />
      </Sider>
      <AppLayout>
        <Header style={{ background: colorBgHeader }}>
          <HeaderLayout
            toggle={() => setCollapsed(!collapsed)}
            collapsed={collapsed}
          />
        </Header>
        <Content
          className={defaultAccess ? defaultAccess.toLowerCase() : ''}
          style={{
            margin: 0,
            height: 'calc(100vh - 62px)',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}>
          {tabCreating && (
            <div className='center-align-loader'>
              <Spin size='default' tip={'Creating tab'} />
            </div>
          )}
          <div
            className={`dashboard-content ${
              isUserHasAccess ? 'has-access' : 'no-access'
            } ${!dashboardClicked ? 'display-none' : ''}`}
            style={{ position: 'relative' }}>
            <SearchCriteria view={'dashboard'} tabKey={'dashboard'} />
            {tabExists && (
              <Tabs
                hideAdd
                onChange={(key) => onChange(key)}
                activeKey={activeKey}
                type='editable-card'
                className='dashboard-tab'
                onTabClick={() =>
                  dispatch({
                    type: 'layout/SWITCH_CONTENT_LAYOUT_SUCCESS',
                    payload: false,
                  })
                }
                onEdit={(targetKey, action) => onEdit(targetKey, action)}>
                {tabs.map((pane) => {
                  return (
                    <TabPane
                      tab={getTabTitle(activeKey, pane)}
                      closeIcon={null}
                      key={pane.key}></TabPane>
                  );
                })}
              </Tabs>
            )}
            {props.children}
          </div>
          {tabExists && (
            <div
              className={`tab-content ${
                dashboardClicked ? 'display-none' : ''
              }`}
              style={{ position: 'relative' }}>
              <SearchCriteria view={'tabs'} tabKey={activeKey} />
              <Tabs
                hideAdd
                onChange={(key) => onChange(key)}
                activeKey={activeKey}
                type='editable-card'
                onEdit={(targetKey, action) => onEdit(targetKey, action)}>
                {tabs.map((pane) => (
                  <TabPane
                    tab={getTabTitle(activeKey, pane)}
                    key={pane.key}
                    closeIcon={
                      pane.key === activeKey ? (
                        <CloseOutlined ref={tourReferences.tabCloseTour} />
                      ) : null
                    }
                    className={'app-tabpane ' + pane.title.replace(' ', '-')}>
                    {pane.content ? (
                      React.createElement(
                        pane.content,
                        { ...pane, tabKey: pane.key },
                        null
                      )
                    ) : (
                      <div className='center-align-loader'>
                        <Spin size='default' tip={'Loading tab content...'} />
                      </div>
                    )}
                  </TabPane>
                ))}
              </Tabs>
            </div>
          )}
        </Content>
        <FooterLayout
          copyRights={copyRights}
          isLoading={authorizationRequest}
          broadcast={broadcast}
          defaultAccess={defaultAccess}
          portalVersion={portalVersion}
          hostName={hostName}
        />
      </AppLayout>
    </AppLayout>
  );
};

Layout.propTypes = { children: PropTypes.element };

export default Layout;
