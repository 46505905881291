import { ExclamationCircleOutlined } from '@ant-design/icons';
import store from '../../../../redux/store';
import service from '../../../../service/Service';
import {
  getArrayOfMondays,
  getLastRowIndex,
  getUUID,
  nullifyEmptyStrInObj,
} from '../../../../helpers/utils';
import { message, Modal, Row, Col } from 'antd';
import moment from 'moment';
import Cookie from 'js-cookie';
import {
  exportDTo,
  getMassDownloadGridParams,
} from '../../../../helpers/export';
import { getFormattedDateStr } from '../helpers/components/component-utils';

export const RTF_FIRM_RELEASE_RESPONSE_COLUMNS =
  'pc/rtf/firm-release/RTF_FIRM_RELEASE_RESPONSE_COLUMNS';
export const RTF_FIRM_RELEASE_RESPONSE_COLUMNS_SUCCESS =
  'pc/rtf/firm-release/RTF_FIRM_RELEASE_RESPONSE_COLUMNS_SUCCESS';
export const RTF_FIRM_RELEASE_RESPONSE_COLUMNS_FAIL =
  'pc/rtf/firm-release/RTF_FIRM_RELEASE_RESPONSE_COLUMNS_FAIL';

export const RTF_FIRM_RELEASE_RESPONSE_ROWS_INFO =
  'pc/rtf/firm-release/RTF_FIRM_RELEASE_RESPONSE_ROWS_INFO';

export const UPDATE_HEADER_DETAILS = 'pc/rtf/UPDATE_HEADER_DETAILS';

export const RTF_FIRM_RELEASE_RESPONSE_SUBMIT =
  'pc/rtf/firm-release/RTF_FIRM_RELEASE_RESPONSE_SUBMIT';
export const RTF_FIRM_RELEASE_RESPONSE_SUBMIT_SUCCESS =
  'pc/rtf/firm-release/RTF_FIRM_RELEASE_RESPONSE_SUBMIT_SUCCESS';
export const RTF_FIRM_RELEASE_RESPONSE_SUBMIT_FAIL =
  'pc/rtf/firm-release/RTF_FIRM_RELEASE_RESPONSE_SUBMIT_FAIL';

export const FETCH_RTF_HEADER_DETAILS =
  'pc/rtf/firm-release/FETCH_RTF_HEADER_DETAILS';
export const FETCH_RTF_HEADER_DETAILS_SUCCESS =
  'pc/rtf/firm-release/FETCH_RTF_HEADER_DETAILS_SUCCESS';
export const FETCH_RTF_HEADER_DETAILS_FAILURE =
  'pc/rtf/firm-release/FETCH_RTF_HEADER_DETAILS_FAILURE';

export const RTF_FIRM_RELEASE_RESPONSE_ROW_DATA_UPDATE =
  'pc/rtf/firm-release/RTF_FIRM_RELEASE_RESPONSE_ROW_DATA_UPDATE';

export const DESTROY_RTF_FIRM_RELEASE_RESPONSE_BY_KEY =
  'pc/rtf/firm-release/DESTROY_RTF_FIRM_RELEASE_RESPONSE_BY_KEY';

export const SET_FOCUSED_CELL_EDITOR =
  'pc/rtf/firm-release/SET_FOCUSED_CELL_EDITOR';

export const UPDATE_RTF_COLUMN_CONFIG =
  'pc/rtf/firm-release/UPDATE_RTF_COLUMN_CONFIG';

export const SET_PICKER_INFO_RTF = 'pc/rtf/SET_PICKER_INFO_RTF';

const initialState = {
  newTab0: {
    loading: false,
    rowsInfo: {
      endRow: 0,
      lastRow: 0,
    },
    rtfFirmReleaseResponseColumns: {},
    rtfHeaderDetailsData: {},
    submittingItem: false,
    submittedItem: true,
    exitRowData: false,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_RTF_HEADER_DETAILS:
    case RTF_FIRM_RELEASE_RESPONSE_COLUMNS:
      return {
        ...state,
        [action.tabKey]: { ...state[action.tabKey], loading: true },
      };
    case FETCH_RTF_HEADER_DETAILS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          rtfHeaderDetailsData: action.payload,
        },
      };
    case FETCH_RTF_HEADER_DETAILS_FAILURE:
    case RTF_FIRM_RELEASE_RESPONSE_COLUMNS_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          error: action.payload,
        },
      };
    case RTF_FIRM_RELEASE_RESPONSE_COLUMNS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          rtfFirmReleaseResponseColumns: action.payload,
          loaded: true,
        },
      };
    case RTF_FIRM_RELEASE_RESPONSE_ROWS_INFO:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          ...(state[action.tabKey] ? state[action.tabKey] : {}),
          rowsInfo: action.payload,
        },
      };
    case UPDATE_HEADER_DETAILS:
      return {
        ...state,
        submittingItem: true,
      };
    case RTF_FIRM_RELEASE_RESPONSE_SUBMIT:
      return {
        ...state,
        [action.tabKey]: { ...state[action.tabKey], submittingItem: true },
      };
    case RTF_FIRM_RELEASE_RESPONSE_SUBMIT_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          submittingItem: false,
          submittedItem: true,
        },
      };
    case RTF_FIRM_RELEASE_RESPONSE_SUBMIT_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          submittingItem: false,
          error: action.payload,
        },
      };
    case DESTROY_RTF_FIRM_RELEASE_RESPONSE_BY_KEY:
      delete state[action.tabKey];
      return state;
    case RTF_FIRM_RELEASE_RESPONSE_ROW_DATA_UPDATE:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          exitRowData: action.payload,
          disabledColumns: action.disabledColumns,
        },
      };
    case SET_FOCUSED_CELL_EDITOR:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          focusedCell: {
            colId: action.colId,
            rowIndex: action.rowIndex,
          },
        },
      };
    case UPDATE_RTF_COLUMN_CONFIG:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          ['rtfFirmReleaseResponseColumns']: {
            ...state[action.tabKey]['rtfFirmReleaseResponseColumns'],
            columnDefs: action.columnConfig,
          },
        },
      };
    case SET_PICKER_INFO_RTF:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          datePicker: {
            ...action.payload,
          },
        },
      };
    default:
      return state;
  }
}

const stringify = (val = '') => {
  if (typeof val !== 'string') {
    const filteredVal = val.filter((data) => data !== 'all');
    return filteredVal.join();
  } else {
    return val;
  }
};

const getSplitRows = (rowData, parentId) => {
  const splitDataSize = rowData.length;
  const totalSplitSize = 3;
  const emptyObjectSize = totalSplitSize - splitDataSize;
  const emptyObjArr = [];
  let emptyObjSplitId = splitDataSize;
  let splitId = 1;

  let existingSplitData = [];
  if (rowData.length) {
    existingSplitData = rowData.map((row, i) => {
      const splitIdIncr = splitId++;
      return {
        ...row,
        id: `${parentId}__${splitIdIncr}`,
        originalValues: { ...row },
        split_id: i + 1,
        parentId,
      };
    });
  }

  if (emptyObjectSize) {
    for (let i = 0; i < emptyObjectSize; i++) {
      const splitIdIncr = splitId++;
      emptyObjArr.push({
        originalValues: {},
        split_id: emptyObjSplitId + 1,
        id: `${parentId}__${splitIdIncr}`,
        parentId,
      });
      emptyObjSplitId += 1;
    }
  }

  return [...existingSplitData, ...emptyObjArr];
};

const getEndRow = (data, params) =>
  params.request.endRow > data.result.lastRow
    ? data.result.lastRow
    : params.request.endRow;

const getGridSuccessResponse = (rowData, params) => {
  const doingInfinite = !params.request.groupKeys.length;
  return doingInfinite
    ? { rowData, rowCount: getLastRowIndex(params.request, rowData) }
    : { rowData };
};

const handleNoRowsOverlay = (rowData, params) => {
  const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
  if (!rowData.length && params.request.startRow < rowsPerRequest) {
    params.api.showNoRowsOverlay();
  }
};

const getView = (viewType, parentId) => {
  return !parentId.length ? viewType : `${viewType}_SPLIT`;
};

const getQsProps = (tabKey) => {
  const qsState = store.getState().pc.search.quickSearch;
  const tabData = qsState[tabKey] || {};
  return {
    customer_plant: stringify(tabData.customerPlant),
    supplier_name: stringify(tabData.supplierName),
    supplier_code: stringify(tabData.supplierCode),
    supplier_key: stringify(tabData.supplierDepot),
    agility_type: stringify(tabData.agilityType),
    site_name: stringify(tabData.supplierSite),
    search_term: tabData.searchTerm,
  };
};

function getRtfFirmReleaseResponseColumns(_AsnEnabledStatus, tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: RTF_FIRM_RELEASE_RESPONSE_COLUMNS, tabKey });
    return api
      .post(
        'pc',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: 'RTF_FIRM_RELEASE_RESPONSE',
            details: {
              email: Cookie.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data && data.statusCode === '200') {
          let {
            detailCellRendererParams,
            masterDetail,
            detailRowAutoHeight,
            groupSelectsChildren,
            animateRows,
            serverSideStoreType,
            ...configs
          } = data.result;

          const columnDefs = configs.columnDefs.map((col) => {
            if (col.field == 'ship_date') {
              let colModified = {
                ...col,
                filter: 'agSetColumnFilter',
                filterParams: {
                  ...configs.defaultColDef.filterParams,
                  values: getArrayOfMondays(10),
                  suppressSorting: true,
                  defaultToNothingSelected: true,
                },
              };
              return nullifyEmptyStrInObj(colModified);
            }
            return nullifyEmptyStrInObj(col);
          });

          dispatch({
            type: RTF_FIRM_RELEASE_RESPONSE_COLUMNS_SUCCESS,
            payload: { ...configs, columnDefs },
            tabKey,
          });
        } else {
          dispatch({
            type: RTF_FIRM_RELEASE_RESPONSE_COLUMNS_FAIL,
            payload: 'Error in loading testing form!',
            tabKey,
          });
        }
      });
  };
}

const getSupplierCommitQty = (row) =>
  row.has_split_data === true ? row.split_count : row.supplier_commit_qty;

const getRowDataFromResponse = (data, AsnEnabledStatus) =>
  data.result.rowData.map((row) => {
    return {
      ...row,
      isEditing: false,
      errors: {},
      hasError: false,
      id: `${getUUID()}$$$${row.customer_code}~${row.customer_plant}~${
        row.supplier_key
      }~${row.part}~${row.forecast_id}`,
      group: true,
      supplier_commit_qty: getSupplierCommitQty(row),
      originalValues: {
        ...row,
        supplier_commit_qty: getSupplierCommitQty(row),
      },
      asnEnabledStatus: AsnEnabledStatus,
      mainRowReadonly: false,
      splitedRowReadonly: false,
      isChildNodeSelected: false, //used for when child node selected, parent row should be disabled
    };
  });

const getRowsFromResponseFirmRelease = (
  data,
  params,
  tabKey,
  AsnEnabledStatus
) => {
  let rowData = [];
  if (params.request.groupKeys.length) {
    rowData = getSplitRows(data.result.rowData, params.request.groupKeys[0]);
  } else {
    rowData = getRowDataFromResponse(data, AsnEnabledStatus);
    const endRow = getEndRow(data, params);
    store.dispatch({
      type: RTF_FIRM_RELEASE_RESPONSE_ROWS_INFO,
      payload: { lastRow: data.result.lastRow, endRow },
      tabKey,
    });
  }

  return rowData;
};

const getParentId = (params) => {
  if (params.request.groupKeys.length) {
    const parentKey = params.request.groupKeys.join();
    const parentKeyExtracted = parentKey.split('$$$')[1];
    return parentKeyExtracted;
  } else {
    return '';
  }
};

function getRtfFirmReleaseResponseRows(AsnEnabledStatus, tabKey) {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      const qsState = store.getState().pc.search.quickSearch;
      const tabData = qsState[tabKey] || {};
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: getView(
                `RTF_FIRM_RELEASE_RESPONSE`,
                params.request.groupKeys
              ),
              details: {
                email: Cookie.get('email'),
                customer_plant: stringify(tabData.customerPlant),
                supplier_name: stringify(tabData.supplierName),
                supplier_code: stringify(tabData.supplierCode),
                supplier_key: stringify(tabData.supplierDepot),
                agility_type: stringify(tabData.agilityType),
                site_name: stringify(tabData.supplierSite),
                search_term: tabData.searchTerm,
                parentId: getParentId(params),
              },
              gridParams: { ...params.request },
            },
            store.getState
          )
        )
        .then((response) => {
          const { data } = response;
          if (data && data.statusCode === '200') {
            const rowData = getRowsFromResponseFirmRelease(
              data,
              params,
              tabKey,
              AsnEnabledStatus
            );
            const result = getGridSuccessResponse(rowData, params);

            setTimeout(() => {
              params.success(result);
            }, 200);

            // Hide overlay loading on success
            params.api.hideOverlay();
            params.api.refreshHeader();
            handleNoRowsOverlay(rowData, params);
          } else {
            params.fail();
          }
        })
        .catch((_error) => {
          params.fail();
        });
    },
  };
}

const isEmptyValue = (value) => value == null || value == '' || value == ' ';

const getAsnEnabledNodeDetails = (node) => ({
  plant: node.customer_plant,
  part: node.part,
  supplier_key: node.supplier_key,
  mpn: node.rel_mpn,
  price: String(node.price),
  reject: isEmptyValue(node.action)
    ? 'N'
    : node.action == 'Yes' || node.action == 'Y'
    ? 'Y'
    : 'N',
  forecast_id: node.forecast_id,
  uom: node.uom,
  po: node.rel_bpo,
  po_release: isEmptyValue(node.mrp_bpo_rel_no) ? null : node.mrp_bpo_rel_no,
  portal_asn_flag: node.asn_flag,
  shipment_no: isEmptyValue(node.shipment_no) ? null : node.shipment_no,
  asn_ship_qty: node.asn_ship_qty,
  ship_date: node.ship_date,
  expt_receipt_date:
    node.expt_receipt_date == null ? '' : node.expt_receipt_date,
  line_number: node.line_number == null ? '' : node.line_number,
  carrier: isEmptyValue(node.carriers) ? null : node.carriers,
  service_level: node.service_level == null ? '' : node.service_level,
  ship_method: node.ship_method == null ? '' : node.ship_method,
  packing_slip: isEmptyValue(node.packing_slip) ? null : node.packing_slip,
  waybill_number: isEmptyValue(node.waybill_number)
    ? null
    : node.waybill_number,
  net_weight: isEmptyValue(node.net_weight) ? null : node.net_weight,
  weight_units: node.weight_units == null ? '' : node.weight_units,
});

const getAsnDisabledCommitArr = (node, callBack) => {
  node.splitData.forEach((splitNode) => {
    if (splitNode.supplier_commit_qty) {
      callBack({
        arrival_date: getFormattedDateStr(splitNode.supplier_arrival_date),
        rtf_qty: splitNode.supplier_commit_qty,
      });
    }
  });
};

const getAsnDisabledNodeDetails = (node) => ({
  plant: node.customer_plant,
  part: node.part,
  supplier_key: node.supplier_key,
  mpn: node.rel_mpn,
  price: String(node.price),
  reject: isEmptyValue(node.action)
    ? 'N'
    : node.action == 'Yes' || node.action == 'Y'
    ? 'Y'
    : 'N',
  forecast_id: node.forecast_id,
  uom: node.uom,
  po: node.rel_bpo,
  po_release: isEmptyValue(node.mrp_bpo_rel_no) ? null : node.mrp_bpo_rel_no,
});

const prepareReleaseCommitData = (masterNodes, AsnEnabledStatus) => {
  let commitPartArray = [];
  masterNodes.forEach((node, i) => {
    let commitDetailsArray = [];

    if (!AsnEnabledStatus) {
      getAsnDisabledCommitArr(node, (d) => commitDetailsArray.push(d));
    }

    if (AsnEnabledStatus && node.asn_flag == 'Yes') {
      commitPartArray.push(getAsnEnabledNodeDetails(node));
    } else {
      commitPartArray.push(getAsnDisabledNodeDetails(node));
    }

    if (commitDetailsArray.length > 0) {
      commitPartArray[i].CommitDetailsArray0 =
        commitDetailsArray[0].arrival_date + '|0'; //main row content when splitted
      if (commitDetailsArray.length == 1) {
        commitPartArray[i].CommitDetailsArray1 =
          commitDetailsArray[0].arrival_date +
          '|' +
          commitDetailsArray[0].rtf_qty;
        commitPartArray[i].CommitDetailsArray2 = null;
        commitPartArray[i].CommitDetailsArray3 = null;
      } else if (commitDetailsArray.length == 2) {
        commitPartArray[i].CommitDetailsArray1 =
          commitDetailsArray[0].arrival_date +
          '|' +
          commitDetailsArray[0].rtf_qty;
        commitPartArray[i].CommitDetailsArray2 =
          commitDetailsArray[1].arrival_date +
          '|' +
          commitDetailsArray[1].rtf_qty;
        commitPartArray[i].CommitDetailsArray3 = null;
      } else {
        commitPartArray[i].CommitDetailsArray1 =
          commitDetailsArray[0].arrival_date +
          '|' +
          commitDetailsArray[0].rtf_qty;
        commitPartArray[i].CommitDetailsArray2 =
          commitDetailsArray[1].arrival_date +
          '|' +
          commitDetailsArray[1].rtf_qty;
        commitPartArray[i].CommitDetailsArray3 =
          commitDetailsArray[2].arrival_date +
          '|' +
          commitDetailsArray[2].rtf_qty;
      }
    } else {
      commitPartArray[i].CommitDetailsArray0 =
        getFormattedDateStr(node.supplier_arrival_date) +
        '|' +
        node.supplier_commit_qty;
      commitPartArray[i].CommitDetailsArray1 = null;
      commitPartArray[i].CommitDetailsArray2 = null;
      commitPartArray[i].CommitDetailsArray3 = null;
    }
  });

  return {
    CommitPartArray: commitPartArray,
  };
};

function submitData(nodes, AsnEnabledStatus, callback, tabKey, t) {
  let headers = {};

  headers = prepareReleaseCommitData(nodes, AsnEnabledStatus);

  return (dispatch, _getState, { api }) => {
    dispatch({ type: RTF_FIRM_RELEASE_RESPONSE_SUBMIT, tabKey });
    return api
      .post('pcsubmitaction', {
        TXN: {
          Header: {
            uuid: getUUID(),
            source: 'PORTAL',
            txn_type: 'RELEASE_COMMIT',
            excel: 'NO',
            requestor: Cookie.get('email'),
            customer: 'SANM',
          },
          ...headers,
        },
      })
      .then((response) => {
        const { data } = response;
        if (
          data == 1 ||
          data == 'SUCCESS' ||
          (data.TXN && data.TXN[0].return_msg == 'SUCCESS')
        ) {
          callback(true);
          message.success(t('data_saved_successfully'));
        } else {
          const failureResponse = data.TXN?.[0]?.CommitPartArray;
          if (!failureResponse || failureResponse.length == 0) {
            callback(false);
            message.error(t('something_went_wrong'));
          } else {
            if (failureResponse.length == nodes.length) callback(false);
            else callback(true);

            Modal.error({
              className: 'ccut-text',
              title: 'Update Failed!!',
              icon: <ExclamationCircleOutlined />,
              width: 520,
              content: (
                <div
                  style={{
                    width: 430,
                    overflowY: 'auto',
                  }}>
                  <div
                    style={{
                      borderBottom: '1px solid #CCC',
                      padding: 5,
                      fontSize: 12,
                      fontWeight: 'bold',
                    }}>
                    <Row>
                      <Col style={{ textAlign: 'center' }} span={2} order={1}>
                        Plant
                      </Col>
                      <Col style={{ textAlign: 'center' }} span={6} order={2}>
                        Supplier
                      </Col>
                      <Col style={{ textAlign: 'center' }} span={7} order={3}>
                        Part
                      </Col>
                      <Col style={{ textAlign: 'center' }} span={9} order={4}>
                        Error
                      </Col>
                    </Row>
                  </div>
                  {failureResponse[0].map((item) => (
                    <div
                      key={getUUID()}
                      style={{
                        borderBottom: '1px solid #CCC',
                        padding: 5,
                        fontSize: 10,
                      }}>
                      <Row>
                        <Col style={{ textAlign: 'center' }} span={2} order={1}>
                          {item.customer_plant}
                        </Col>
                        <Col style={{ textAlign: 'center' }} span={6} order={2}>
                          {item.supplier_key}
                        </Col>
                        <Col style={{ textAlign: 'center' }} span={7} order={3}>
                          {item.part}
                        </Col>
                        <Col
                          style={{
                            textAlign: 'center',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                          span={9}
                          order={4}>
                          <span title={item.error_message}>
                            {item.error_message}
                          </span>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
              ),
            });
          }
        }
      });
  };
}

export function getMassDownloadReport({
  gridApi,
  exportType,
  totalRows,
  cb,
  columnApi,
  fileName,
  view,
  tabKey,
}) {
  const handleRowsWithSplitAdd = (result, callBack) => {
    result.rowData?.forEach((row) => {
      const { splitRecords, ...rest } = row;
      callBack(rest);
      if (splitRecords?.length) {
        splitRecords.forEach((splitRow) => callBack(splitRow));
      }
    });
  };

  return (_dispatch, getState, { api, formatParams }) => {
    if (totalRows >= process.env.REACT_APP_MASS_DOWNLOAD_ENABLE_LIMIT) {
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'MASS_DOWNLOAD',
              view: view,
              exportType,
              totalRows,
              email: Cookie.get('email'),
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
              },
              details: {
                email: Cookie.get('email'),
                ...getQsProps(tabKey),
              },
            },
            getState
          )
        )
        .then((response) => {
          cb(true);
          return response;
        })
        .catch((error) => {
          cb(false);
          return error;
        });
    } else {
      return api
        .post(
          'pc',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: view,
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
                startRow: 0,
                endRow: totalRows,
              },
              details: {
                email: Cookie.get('email'),
                ...getQsProps(tabKey),
              },
            },
            store.getState
          )
        )
        .then((response) => {
          const { data } = response;
          if (data) {
            const { result } = data;
            if (Object.keys(result).length) {
              let rowsWithSplitAdded = [];
              handleRowsWithSplitAdd(result, (d) => rowsWithSplitAdded.push(d));
              exportDTo({
                columnApi,
                fileName: fileName,
                data: rowsWithSplitAdded,
                exportType,
              }) && cb(true);
            }
          }

          return response;
        });
    }
  };
}

const destroyRtfFirmReleaseResponseByKey = (tabKey) => {
  return (dispatch) => {
    dispatch({ type: DESTROY_RTF_FIRM_RELEASE_RESPONSE_BY_KEY, tabKey });
  };
};

const exitRtfFirmReleaseRowDataUpdateByKey = (
  tabKey,
  isModified,
  disabledColumns
) => {
  return (dispatch) => {
    dispatch({
      type: RTF_FIRM_RELEASE_RESPONSE_ROW_DATA_UPDATE,
      payload: isModified,
      disabledColumns,
      tabKey,
    });
  };
};

const setFocusedCell = (colId, rowIndex, tabKey) => {
  return (dispatch) => {
    dispatch({ type: SET_FOCUSED_CELL_EDITOR, colId, rowIndex, tabKey });
  };
};

const updateRtfColumnConfig = (columnConfig, tabKey) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_RTF_COLUMN_CONFIG, columnConfig, tabKey });
  };
};

const setPickerInfo = (payload, tabKey) => {
  return (dispatch) => {
    dispatch({
      type: SET_PICKER_INFO_RTF,
      payload,
      tabKey,
    });
  };
};

export {
  getRtfFirmReleaseResponseColumns,
  getRtfFirmReleaseResponseRows,
  submitData,
  destroyRtfFirmReleaseResponseByKey,
  exitRtfFirmReleaseRowDataUpdateByKey,
  setFocusedCell,
  updateRtfColumnConfig,
  setPickerInfo,
};
